<template>
	<div data-route>
		<page-header
			heading="Transactions"
		/>
		<div data-element="main">
			<transactions
				entryRoute="teaching"
			/>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isTeacherOnly": true
		}
	}
</route>

<script>

	import PageHeader    from '@/components/ui/PageHeader';
	import Transactions  from '@/components/transactions/Transactions';

	export default {
		metaInfo: {
			title: 'Transactions'
		},
		components: {
			Transactions,
			PageHeader
		},
		data: () => ({}),
		computed: {},
		mounted () {}
	};
</script>
